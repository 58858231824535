import React from 'react'
import { FaBlog } from '@react-icons/all-files/fa/FaBlog'
import { FaDog} from '@react-icons/all-files/fa/FaDog'
import {FaCat} from '@react-icons/all-files/fa/FaCat'
import { FaGlobeAmericas } from '@react-icons/all-files/fa/FaGlobeAmericas'
import {FaPhone} from '@react-icons/all-files/fa/FaPhone'
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle'



export const translations = {
    "en-EU": {
        analysis: "Guaranteed Analysis",
        functionals: "Functional Additives",
        ingredients: "Ingredients",
        recommendation: "Feeding Recommendation",
        packages: "Package Options",
        prodDetails: "Product Details",
        fullName: "Full Name",
        company: "Company",
        mobileNo: 'Mobile Phone No',
        subject: 'Subject',
        message: 'Message',
        email: 'E-Mail',
        submit: 'Submit',
        contactUs: 'Contact Us',
        messageDelivered: 'Message successfully delivered. We will get back to you.',
        messageNotDlvrd: 'Something went wrong. Please try again later',
        homepage: 'Home',
        cat: 'For Cats',
        dog: 'For Dogs',
        dryFood: 'Dry Food',
        wetFood: 'Wet Food',
        watch: 'WATCH VIDEO',
        readMore: 'Read More'

    },
    "tr-TR": {
        analysis: "Garanti Analiz",
        functionals: "Besinsel İlaveler",
        ingredients: "İçerik",
        recommendation: "Besleme Tavsiyesi",
        packages: "Paketler",
        prodDetails: "Ürün Ayrıntıları",
        fullName: "Ad Soyad",
        company: "Şirket Ünvanı",
        mobileNo: 'Cep Telefonu',
        subject: 'Konu',
        message: 'Mesaj',
        email: 'E-Posta',
        submit: 'Gönder',
        contactUs: 'İletişim',
        messageDelivered: 'Mesajınız bize ulaştı. Mutlaka dönüş yapacağız.',
        messageNotDlvrd: 'Birşeyler ters gitti. Daha sonra tekrar deneyiniz.',
        homepage: 'Anasayfa',
        cat: 'Kedi',
        dog: 'Köpek',
        dryFood: 'Kuru Mama',
        wetFood: 'Yaş Mama',
        watch: 'İZLE',
        readMore: 'Devamını Oku'
    },
}

export const menuData = {
    'tr-TR': [
        {
            label: 'Kedi',
            link: '/urunler/kedi/',
            icon: <FaCat />,
            subMenus: [{
                label: 'Kuru Mamalar',
                link: '/urunler/kedi/kuru-mamalar/',
            },
            {
                label: 'Yaş Mamalar',
                link: '/urunler/kedi/yas-mamalar/',
            }
            ]
        },
        {
            label: 'Köpek',
            link: '/urunler/kopek/',
            icon: <FaDog />,
            subMenus: [{
                label: 'Kuru Mamalar',
                link: '/urunler/kopek/kuru-mamalar/',
            },
            {
                label: 'Yaş Mamalar',
                link: '/urunler/kopek/yas-mamalar/',
            }
            ]
        },
        {
            label: 'Hakkımızda',
            link: '/hakkimizda/',
            icon: <FaInfoCircle/>,
            subMenus: [{
                label: 'Biz Kimiz?',
                link: '/hakkimizda/biz-kimiz/'
            },
            {
                label: 'Değerlerimiz',
                link: '/hakkimizda/degerlerimiz/'
            },
            {
                label: 'İçerikler',
                link: '/hakkimizda/icerikler/'
            },
            {
                label: 'ProParent',
                link: '/hakkimizda/pro-ebeveyn/'
            },
            {
                label: 'Sürdürebilirlik',
                link: '/hakkimizda/surdurulebilirlik/'
            },

            ]
        },
        {
            label: 'Blog',
            link: '/blog/',
            icon: <FaBlog />,
        },

        {
            label: 'İletişim',
            link: '/iletisim/',
            icon: <FaPhone />,
        },
      
    ],
    'en-EU': [
        {
            label: 'Cats',
            link: '/en/products/cat/',
            icon: <FaCat />,
            subMenus: [{
                label: 'Dry Food',
                link: '/en/products/cat/dry-food/',
            },
            {
                label: 'Wet Food',
                link: '/en/products/cat/wet-food/',
            },
            ]
        },
        {
            label: 'Dogs',
            link: '/en/products/dog/',
            icon: <FaDog />,
            subMenus: [{
                label: 'Dry Food',
                link: '/en/products/dog/dry-food/',
            },
            {
                label: 'Wet Food',
                link: '/en/products/dog/wet-food/',
            },
            ]
        },
        {
            label: 'About Us',
            link: '/en/about-us/',
            icon: <FaInfoCircle/>,
            subMenus: [{
                label: 'Who We Are',
                link: '/en/about-us/who-we-are/',
            },
            {
                label: 'Our Values',
                link: '/en/about-us/our-values/',
            },
            {
                label: 'ProParent',
                link: '/en/about-us/pro-parent/',
            },
            {
                label: 'Ingredients',
                link: '/en/about-us/ingredients/'
            },
            {
                label: 'Sustainability',
                link: '/en/about-us/sustainability/',
            },
            ]
        },
        {
            label: 'Blog',
            link: '/en/blog/',
            icon: <FaBlog />,
        },
        {
            label: 'Exports',
            link: '/en/exports/',
            icon: <FaGlobeAmericas />,
        },
        {
            label: 'Contact Us',
            link: '/en/contact/',
            icon: <FaPhone />,
        },
       
    ],

}
export const footerMenuItems = [
    {
    group: 'products',
    items: {
        'tr-TR': [
            {
                label: 'Kedi Kuru Mamaları',
                link: '/urunler/kedi/kuru-mamalar/'
            },
            {
                label: 'Kedi Yaş Mamaları',
                link: '/urunler/kedi/yas-mamalar/'
            },
            {
                label: 'Kopek Kuru Mamaları',
                link: '/urunler/kopek/kuru-mamalar/'
            },
            {
                label: 'Köpek Yaş Mamaları',
                link: '/urunler/kopek/yas-mamalar/'
            },

        ],
        'en-EU': [
            {
                label: 'Dry Food For Cats',
                link: '/en/products/cat/dry-food'
            },
            {
                label: 'Wet Food For Cats',
                link: '/en/products/cat/wet-food'
            },
            {
                label: 'Dry Food For Dogs',
                link: '/en/products/dog/dry-food'
            },
            {
                label: 'Wet Food For Dogs',
                link: '/en/products/dog/wet-food'
            },

        ]
    },

},

{
    group: 'help',
    items: {
        'tr-TR': [
            {
                label: 'Biz Kimiz',
                link: '/hakkimizda/biz-kimiz'
            },
            {
                label: 'Değerlerimiz',
                link: '/hakkimizda/degerlerimiz'
            },
            {
                label: 'İçerikler',
                link: '/hakkimizda/icerikler'
            },
            {
                label: 'ProEbeveyn',
                link: '/hakkimizda/pro-ebeveyn'
            },
            {
                label: 'Sürdürebilirlik',
                link: '/hakkimizda/surdurulebilirlik'
            },

        ],
        'en-EU': [
            {
                label: 'Who We Are',
                link: '/en/about-us/who-we-are',
            },
            {
                label: 'Our Values',
                link: '/en/about-us/our-values',
            },
            {
                label: 'ProParent',
                link: '/en/about-us/pro-parent',
            },
            {
                label: 'Ingredients',
                link: '/en/about-us/ingredients'
            },
            {
                label: 'Sustainability',
                link: '/en/about-us/sustainability',
            },

        ]

    },
    
},
{
    group:'social',
    items:[
        {
            label:'Facebook',
            link:'https://www.facebook.com/prochoicepetfood'
        },
        {
            label:'Instagram',
            link:'https://www.instagram.com/prochoicepetfood/'
        }
    ]
}
]