
import axios from 'axios'

export function prepMessage(data) {
    return (`
        
            Ad, Soyad: ${data.fullName}
            Firma Adı: ${data.company}
            E-Posta: ${data.email}
            Telefon No: ${data.mobileNo}
            Mesaj: ${data.message}
        
    `)
}

export async function postEmail(data){
    const {subject, message, toAddress} =data
    const body = {
        email: toAddress,
        subject: subject,
        message: message
    }
    const url = process.env.GATSBY_EMAIL_API_URL
    let response
    try {
        response = await axios.post(url,body)
        return(response)
    } catch (error) {
        console.log(error)
        return(error)
    }


}