import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {translations} from '../intl/translations'
import { postEmail, prepMessage } from '../utilities/email'
import { Alert } from '@mui/material';

const emailPattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const useStyles = makeStyles(theme => ({
    hP: {
        display: 'none'
    },
    paragraph:{
        marginBottom:theme.spacing(2)
    },
    input:{
        borderRadius:0,
       
    }
}))

function isEmpty(obj) {
    for (var x in obj) { return false; }
    return true;
}

export default function ContactForm(props) {
    const [submitSuccessful, setSubmitSuccessful] = useState()
    const classes = useStyles()
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const nodeLoc = props?.nodeLocale || 'tr-TR'
    const onSubmit = async data => {
        if (data.compEmail !== '') { console.log("You are not human aren't you?"); return }
        const messageBody = prepMessage(data)
        const response = await postEmail({ subject: `[Brit Care Web Sitesinden]:${data.subject}`, message: messageBody, toAddress: 'info@atlaspet.com.tr' })
        if (response.status === 200) {
            setSubmitSuccessful(true)
            setTimeout(() => {
                setSubmitSuccessful();
                reset();
            }, 5000)
        } else {
            setSubmitSuccessful(false)
        }
        
    }

    return (
        <Grid container>

            <Grid item xs={12}>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Alert style={{visibility: (typeof submitSuccessful !== 'undefined')? 'visible':'hidden'}}
                    severity={submitSuccessful ? 'success' : 'error'}>{submitSuccessful ? translations[nodeLoc].messageDelivered :
                        translations[nodeLoc].messageNotDlvrd}</Alert>
                    <Grid container spacing={2} >
                        <Grid item md={6} xs={12}>
                            <TextField fullWidth type="text" placeholder={translations[nodeLoc].fullName} {...register('fullName',{ required: true, maxLength: 100 })} error={errors.hasOwnProperty('fullName')} variant="outlined" InputProps={{classes:{root:classes.input}}}/>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.hP}>
                            <TextField fullWidth type="text" placeholder="companyEmail" name="compEmail" {...register('compEmail')} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField fullWidth type="text" placeholder={translations[nodeLoc].company} {...register('company')} variant="outlined" InputProps={{classes:{root:classes.input}}}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item md={6} xs={12}>
                            <TextField fullWidth type="text" placeholder={translations[nodeLoc].email}  {...register('email',{ required: true, pattern: emailPattern })} error={errors.hasOwnProperty('email')} variant="outlined" InputProps={{classes:{root:classes.input}}}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField type="tel" placeholder={translations[nodeLoc].mobileNo} {...register('mobileNo',{ required: true, minLength: 6, maxLength: 12 })} error={errors.hasOwnProperty('mobileNo')} variant="outlined" InputProps={{classes:{root:classes.input}}}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1} className={classes.paragraph}>
                        <Grid item md={12} xs={12}>
                            <TextField fullWidth type="text" placeholder={translations[nodeLoc].subject} {...register('subject',{ required: true, max: 300, min: 30, maxLength: 300 })} error={errors.hasOwnProperty('subject')} variant="outlined" InputProps={{classes:{root:classes.input}}}/>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField multiline rows={4} fullWidth type="text" placeholder={translations[nodeLoc].message} {...register('message',{ required: true, max: 300, min: 30, maxLength: 300 })} error={errors.hasOwnProperty('message')} variant="outlined" InputProps={{classes:{root:classes.input}}}/>
                        </Grid>
                    </Grid>


                    <Button type="submit" value='submit' fullWidth 
                    sx={{
                        whiteSpace: 'nowrap',
                        color: 'white',
                        backgroundColor:props.btnBgColor || 'black',
                        borderRadius: 0,
                        "&:hover, &:focus": {
                            color: props.btnBgColor || 'black',
                            backgroundColor: 'white',
                        }
                    }} 
                    
                    variant="contained" disabled={!isEmpty(errors)}>{translations[nodeLoc].submit}</Button>
                </form>
            </Grid>
        </Grid>
    );
}