import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { makeStyles } from '@mui/styles';
import { Grid,Typography } from '@mui/material'
import ContactForm from '../components/contactForm'
import AddressContainer from '../components/addressBox';
import { HiOutlineMail } from '@react-icons/all-files/hi/HiOutlineMail'
import { GoDeviceMobile } from '@react-icons/all-files/go/GoDeviceMobile'
import { FaRegBuilding } from '@react-icons/all-files/fa/FaRegBuilding'
import { britColors } from '../config/colors';


const useStyles = makeStyles((theme) => ({
    contactContainer: {
        marginTop: 20
    },
    formContainer: {
        marginTop: 10
    },
    mapContainer: {
        marginTop: 10
    },
    paragraph: {
        marginBottom: 20
    },
    button: {
        whiteSpace: 'nowrap',
        color: 'white',
        backgroundColor: 'black',
        borderRadius: 0,
        "&:hover, &:focus": {
            color: 'black',
            backgroundColor: 'white',
        }
    },
    headerWrapper: {
        marginTop: 30,
        marginBottom:30
    },
}))

const addressItems = [{
    icon: <FaRegBuilding size='3rem' color={britColors[0]} />,
    text: <Typography variant='body1' component='p'>
        <strong>Atlaspet Pet Ürünleri Pazarlama ve Ticaret A.Ş.</strong><br />
        Göktürk Cad. No: 2 Suvenue Sitesi D Blok D:7<br />
        Göktürk, Eyüp <br />
        İstanbul<br />
        Türkiye
    </Typography>
},
{
    icon: <HiOutlineMail size='3rem' color={britColors[1]}/>,
    text: 'brit@atlaspet.com.tr'
},
{
    icon: <GoDeviceMobile size='3rem' color={britColors[2]}/>,
    text: '+90 212 807 01 40'
}

]

const Header = (props) => {
    return (
        <>
            <Typography  component="h1" variant='h1' >
                İLETİŞİM</Typography>
            <Typography variant="subtitle1">Brit markalı ürünler ile ilgili her türlü konuda bize buradan ulaşabilirsiniz.</Typography>
        </>
    )
}

const ContactPageTr = (props) => {
    const classes = useStyles()
    const nodeLocale = 'tr-TR'
    const { location,pageContext } = props
    const {
        breadcrumb: { crumbs },
      } = pageContext
      console.log(pageContext)
    return (
        <Layout
            nodeLocale={nodeLocale}
            location={location}
            crumbs={crumbs} pageContext={pageContext}
        >
            <Seo
                lang='tr-TR'
                title='İletişim'
                location={location}
            />
            <Grid container >

                <Grid item sm={12}>
                    <Header />
                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item sm={12} md={12} className={classes.formContainer}>
                    <ContactForm nodeLocale={nodeLocale} btnBgColor={britColors[3]}/>
                </Grid>


            </Grid>
            <Grid>
                <AddressContainer items={addressItems} />
            </Grid>
           {/*  <Grid className={classes.mapContainer}>
                <Map
                    id="atlaspetMap"
                    options={{
                        center: { lat: 41.176856, lng: 28.889965 },
                        zoom: 14,
                        styles: [{
                            stylers: [{
                                saturation: -100
                            }]
                        }]

                    }}
                    onMapLoad={map => {
                        var marker = new window.google.maps.Marker({
                            position: { lat: 41.176856, lng: 28.889965 },
                            map: map,
                            title: 'Atlaspet Pet Ürünleri Paz. ve Tic. A.Ş.',
                        })
                    }}
                />
            </Grid> */}

        </Layout>
    )
}
export default ContactPageTr