import React, { useEffect } from 'react'
import {  Typography, Paper, Grid, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'



const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        marginTop: theme.spacing(3),
        gap:'2%',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        }
    },
    gridItem: {
        width: 'auto',
        flex: '1 1 auto',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    paper: {
        height:'100%',
        border: 'solid 0.5px',
        borderColor: theme.palette.action.selected,
        backgroundColor: 'transparent',
        
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop:'3%',


        }
    },
    paperContent: {
        paddingLeft: '10%',
        paddingRight: '10%',
        paddingTop: '10%',
        paddingBottom: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        }

    },
    menuBoxHeader: {
        textDecoration: 'none',
        fontFamily: ['"Helvetica Neue"', 'sans-serif'],
        fontWeight: 500,
        letterSpacing: '-0.75px',
        fontSize: '2rem',
        lineHeight: '3rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
            lineHeight: '1.2',
        },
    },
    link: {
        textDecoration: 'none !important;',
        '&:visited': {
            color: theme.palette.text.primary,
            '-webkit-text-fill-color': theme.palette.text.primary
        },
        color: theme.palette.text.primary,
        '&:hover': {
            color: 'white',
            '-webkit-text-fill-color': 'white'
        },

    },
    menuBoxTextSection: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(5),
        }

    },
    iconBox: {
        padding: 0
    },
    callToAction: {
        color: 'inherit',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1,
            marginTop: '5%'
        }
    },



}))
const AddressBox = (props) => {
    const [state, setState] = React.useState({ mobileView: true })
    const classes = useStyles()
    const { text,  icon  } = props

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);

    return (
        <Paper className={classes.paper} square elevation={0}>
            
                <div className={classes.paperContent}>
                    <div className={classes.iconBox}>
                        {icon}
                    </div>
                    <div className={classes.menuBoxTextSection}>
                        
                        <Typography variant='subtitle1' className={classes.callToAction}>{text}</Typography>
                    </div>
                </div>
           
            {state.mobileView &&
               
                <Divider />
            }
        </Paper>
    )
}

const AddressContainer = (props) => {
    const classes = useStyles()
    return (
        <Grid container className={classes.root}>
            {props.items.map((item, key) => (
                <Grid item key={key} className={classes.gridItem}>
                    <AddressBox
                        
                        text={item.text}
                        icon={item.icon}
                        

                    />
                </Grid>
            ))}

        </Grid>
    )
}

export default AddressContainer